import utils from '../utils/utils'
import anime from '../libs/anime'
import globalVar from '../utils/globalVar'

class Collapse {
  constructor() {
    let cache = {};
    let initFunc = () => {
      initCache();
      registerEvents();
    }

    let initCache = () => {
      cache.collapse = document.querySelectorAll('.collapse_wrap');
    };

    let registerEvents = () => {
      for (let i = 0; i < cache.collapse.length; i++) {
        cache.collapse[i].querySelector('.collapse_top').addEventListener("click", toggleCollapse);
      }
    };

    let toggleCollapse = function() {
      let collapse = this.closest('.collapse_wrap');
      let parent = collapse.parentNode;
      let allOpen = parent.querySelectorAll('.collapse_wrap.open');
      let content = collapse.querySelector('.collapse_container');
      
    // OPEN
    if(!collapse.classList.contains('open')) {
        collapse.classList.add('open');
        content.style.display = "block";
        content.style.height = "auto";
        let height = content.offsetHeight;
        content.style.height = 0;
        anime({
          targets: content,
          height: height,
          duration: 300,
          easing: 'linear',
          complete: function() {
            anime({
              targets: globalVar.scrollElement,
              scrollTop: (globalVar.scrollElement.scrollTop+collapse.getBoundingClientRect().top-160),
              duration: 300,
              easing: 'easeOutQuad'
            });
          }
        });
      }


    // CLOSE  
    if(parent.querySelector('.collapse_wrap.open')) {
      for (let i = 0; i < allOpen.length; i++) {
          let open = allOpen[i];
          open.classList.remove('open');
          anime({
            targets: open.querySelector('.collapse_container'),
            height: 0,
            duration: 300,
            easing: 'linear',
            complete: function() {
              open.querySelector('.collapse_container').style.display = "none";
            }
          });
      }
    }
  };

    initFunc();
  };
}
export { Collapse }
import utils from '../utils/utils'
import anime from '../libs/anime'
import globalVar from '../utils/globalVar'

class PopUpEmergency{
  constructor() {
    let cache = {};
    let initFunc = () => {
      initCache();
    }

    let initCache = () => {
      if(document.querySelector('.popup_global')) {
        cache.popUpGlobal = document.querySelector('.popup_global');
        cache.popUpGlobalClose = document.querySelector('.popup_global .close');
        if(!sessionStorage.getItem("popUpGlobal")) {
          initPopUpGlobal();
        }
        cache.popUpGlobalClose.addEventListener("click", closePopUpGlobal);
      }

      if(document.querySelector('.popup_post')) {
        cache.popUpPostArr = document.querySelectorAll('.popup_post');
        cache.popUpPostClose = document.querySelectorAll('.popup_post .close');
        initPopUpPost();
        for (let i = 0; i < cache.popUpPostClose.length; i++) {
          cache.popUpPostClose[i].addEventListener("click", closePopUpPost);
        }
      }
    };


// POPUP GLOBAL
    let initPopUpGlobal = function() {
      cache.popUpGlobal.style.display = "flex";
      cache.popUpGlobal.style.height = "auto";
      let height = cache.popUpGlobal.offsetHeight;
      cache.popUpGlobal.style.height = 0;
      anime({
        targets: cache.popUpGlobal,
        height: height,
        duration: 300,
        easing: 'easeOutQuad',
      });
      anime({
        targets: document.querySelector('body'),
        paddingTop: (document.querySelector('header').offsetHeight + height),
        duration: 300,
        easing: 'easeOutQuad',
      });
    }

    let closePopUpGlobal = function() {
      let height = cache.popUpGlobal.offsetHeight;
      anime({
        targets: document.querySelector('body'),
        paddingTop: (document.querySelector('header').offsetHeight-height),
        duration: 300,
        easing: 'easeOutQuad',
      });
      anime({
        targets: cache.popUpGlobal,
        height: 0,
        duration: 300,
        easing: 'easeOutQuad',
        complete: function() {
          cache.popUpGlobal.style.display = "none";
          sessionStorage.setItem("popUpGlobal", "close");
        }
      });
    }


// POPUP POST
    let initPopUpPost = function() {
      for (let i = 0; i < cache.popUpPostArr.length; i++) {
        const popup = cache.popUpPostArr[i];
        const popupId = cache.popUpPostArr[i].getAttribute("id");

        if(i > 0) {
          let lastBt = cache.popUpPostArr[i-1].style.bottom;
          if(!lastBt) {
            lastBt = 30;
          } else {
            lastBt = parseInt(cache.popUpPostArr[i-1].style.bottom.replace('px',''));
          }
          const bottom = cache.popUpPostArr[i-1].offsetHeight + 10 + lastBt;
          popup.style.bottom = bottom + 'px';
        }
        if(!sessionStorage.getItem(popupId)) {
          popup.style.display = "flex";
          anime({
            targets: popup,
            opacity: [0, 1],
            translateX: [100, 0],
            duration: 500,
            delay: (300 + (100 * i)),
            easing: 'easeOutQuad',
          });
        }
      }
    }

    let closePopUpPost = function() {
      const popup = this.closest('.popup_post');
      const popupId = popup.getAttribute("id");
      anime({
        targets: popup,
        opacity: [1, 0],
        translateX: [0, 100],
        duration: 500,
        easing: 'easeOutQuad',
        complete: function() {
          popup.style.display = "none";
          sessionStorage.setItem(popupId, "close");
          let active = false;
          for (let i = 0; i < cache.popUpPostArr.length; i++) {
            if(active) {
              let currentBt = parseInt(cache.popUpPostArr[i].style.bottom.replace('px',''));
              if(!currentBt) {
                currentBt = 30;
              }
              let bottom = currentBt - (cache.popUpPostArr[i-1].offsetHeight + 10) ;
              if(cache.popUpPostArr[i-1].style.display == 'none'){
                cache.popUpPostArr[i-1].style.display = "flex";
                bottom = currentBt - (cache.popUpPostArr[i-1].offsetHeight + 10) ;
                cache.popUpPostArr[i-1].style.display = "none";
              }
              if(!bottom) {
                bottom = 30;
              }
              anime({
                targets: cache.popUpPostArr[i],
                bottom: bottom,
                duration: 300,
                easing: 'easeOutQuad',
              });
            } else {
              if(popup == cache.popUpPostArr[i]) {
                active = true;
              }
            }
          }
        }
      });
    }

    initFunc();
  };
}
export { PopUpEmergency }

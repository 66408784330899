import utils from '../utils/utils'
import anime from '../libs/anime'
import Hammer from '../libs/hammer'
import globalVar from '../utils/globalVar'

class ModuleStoriesImages {
  constructor() {
    let cache = {};
    let initFunc = () => {
      initCache();
      registerEvents();
    }

    let initCache = () => {
      cache.sliders = document.querySelectorAll('.module-stories-03-images .slider-image');
    };

    let registerEvents = () => {
      for (let i = 0; i < cache.sliders.length; i++) {
        let slide = cache.sliders[i].querySelectorAll('.slider-image_slide');
        if(slide.length > 1) {
            cache.sliders[i].querySelector('.arrow_left').addEventListener("click", function() {
                slideLeft(cache.sliders[i]);
            });
            cache.sliders[i].querySelector('.arrow_right').addEventListener("click", function() {
                slideRight(cache.sliders[i]);
            });

            let hammertime = new Hammer(cache.sliders[i]);
            hammertime.on('swipe', function(ev) {
            if(ev.direction == 2) {
                slideRight(cache.sliders[i]);
            }
            if(ev.direction == 4) {
                slideLeft(cache.sliders[i]);
            }
          });
        }
      }
    };

    let slideLeft = function(slider) {
        let slides = slider.querySelectorAll('.slider-image_slide');
        let index = parseInt(slider.dataset.index);
        
        let currentSlide = slides[index];

        if(index == 0) {
            index = slides.length-1;
        } else {
            index--;
        }

        let targetSlide = slides[index];
        slider.dataset.index = index;

        anime({
            targets: currentSlide,
            translateX: 100,
            opacity: 0,
            duration: 400,
            easing: 'easeOutQuad',
            complete: function() {
                currentSlide.style.display = "none";
            }
        });

        targetSlide.style.display = "block";
        anime({
            targets: targetSlide,
            translateX: [-100, 0],
            opacity: [0,1],
            duration: 400,
            easing: 'easeOutQuad'
        });
    };

    let slideRight = function(slider) {
        let slides = slider.querySelectorAll('.slider-image_slide');
        let index = parseInt(slider.dataset.index);
        
        let currentSlide = slides[index];

        if(index == slides.length-1) {
            index = 0;
        } else {
            index++;
        }

        let targetSlide = slides[index];
        slider.dataset.index = index;

        anime({
            targets: currentSlide,
            translateX: -100,
            opacity: 0,
            duration: 400,
            easing: 'easeOutQuad',
            complete: function() {
                currentSlide.style.display = "none";
            }
        });

        targetSlide.style.display = "block";
        anime({
            targets: targetSlide,
            translateX: [100, 0],
            opacity: [0,1],
            duration: 400,
            easing: 'easeOutQuad'
        });
    };

    initFunc();
  };
}
export { ModuleStoriesImages }
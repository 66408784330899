import anime from '../libs/anime'
import Hammer from '../libs/hammer'

class Module29_AnnualNumbers {
  constructor() {
    let cache = {};
    let initFunc = () => {
      initCache();
      registerEvents();
      
    }

    let initCache = () => {
      cache.projects = document.querySelectorAll('.module-29-annual-numbers');
    };

    let registerEvents = () => {
      for (let i = 0; i < cache.projects.length; i++) {
        let cards = cache.projects[i].querySelectorAll('.card-project');
        for (let ii = 0; ii < cards.length; ii++) {
            const el = cards[ii];
            el.addEventListener('mouseenter', hoverInCard)
        }
      }
    };

    let hoverInCard = function() {
        let card = this;
        let txt = card.querySelector('.card-project_txt')
        card.removeEventListener('mouseenter', hoverInCard);
        card.addEventListener('mouseleave', hoverOutCard);
        txt.style.display = "block";
        txt.style.height = "auto";
        let height = txt.offsetHeight;
        txt.style.height = 0;
        anime({
            targets: txt,
            height: height,
            duration: 300,
            easing: 'easeOutQuad',
        });
    };
    let hoverOutCard = function() {
        let card = this;
        let txt = card.querySelector('.card-project_txt')
        card.addEventListener('mouseenter', hoverInCard);
        card.removeEventListener('mouseleave', hoverOutCard);
        anime({
            targets: txt,
            height: 0,
            duration: 300,
            easing: 'easeOutQuad',
            complete: function() {
            }
        });
    };

    initFunc();
  };
}
export { Module29_AnnualNumbers }
import utils from "../utils/utils";
import anime from "../libs/anime";
import globalVar from "../utils/globalVar";

class Nav {
	constructor() {
		let prevTab;
		let cache = {};
		let initFunc = () => {
			initCache();
			registerEvents();
			if (window.location.href.indexOf("#") > -1) {
				anime({
					targets: globalVar.scrollElement,
					scrollTop: 0,
					duration: 0,
				});
				scrollToAnchor();
			}
		};

		let initCache = () => {
			cache.navTriggerDesk = document.querySelectorAll(
				"header .nav_top .top-title"
			);
			cache.navTriggerDeskBw = document.querySelector(
				"header .nav_top .btn_menu"
			);
			cache.navTriggerDeskSearch = document.querySelector(
				"header .nav_top .search_btn"
			);
			cache.navCloseDesk = document.querySelector(
				"header .nav_desktop .btn_close"
			);
			cache.navDesk = document.querySelector("header .nav_desktop");
			cache.navFilter = document.querySelector("header .filter");

			cache.navTriggerMob = document.querySelectorAll(
				"header .nav_mobile .top-title"
			);
			cache.navBackMob = document.querySelectorAll(
				"header .nav_mobile .menu_tab .back"
			);
			cache.navMob = document.querySelector("header .nav_mobile");
			cache.navmobileBtn = document.querySelector(".nav_mobile-btn");
		};

		let registerEvents = () => {
			// cache.navmobileBtn.addEventListener("click", openNav);

			if (cache.navTriggerDesk) {
				for (let i = 0; i < cache.navTriggerDesk.length; i++) {
					cache.navTriggerDesk[i].addEventListener("click", openNav);
				}
			}

			if (cache.navTriggerDeskSearch) {
				cache.navTriggerDeskSearch.addEventListener("click", openNav);
			}

			if (cache.navTriggerMob) {
				for (let i = 0; i < cache.navTriggerMob.length; i++) {
					cache.navTriggerMob[i].addEventListener("click", openTabMobile);
				}
			}

			if (cache.navBackMob) {
				for (let i = 0; i < cache.navBackMob.length; i++) {
					cache.navBackMob[i].addEventListener("click", closeTabMobile);
				}
			}

			if (cache.navmobileBtn) {
				cache.navmobileBtn.addEventListener("click", openNavMobile);
			}
		};

		// DESKTOP
		let openNav = function() {
			for (let i = 0; i < cache.navTriggerDesk.length; i++) {
				cache.navTriggerDesk[i].removeEventListener("click", openNav);
				cache.navTriggerDesk[i].addEventListener("click", changeTabNav);
			}

			cache.navTriggerDeskSearch.removeEventListener("click", openNav);

			if (this.classList.contains("btn_menu")) {
				this.addEventListener("click", changeTabNavBwOut);
				cache.navTriggerDeskSearch.addEventListener("click", changeTabNav);
			} else if (this.classList.contains("search_btn")) {
				cache.navTriggerDeskSearch.addEventListener(
					"click",
					changeTabNavSearchOut
				);
				if (cache.navmobileBtn.classList.contains("active")) {
					closeNavMobile();
				}
			} else {
				cache.navTriggerDeskSearch.addEventListener("click", changeTabNav);
				this.addEventListener("click", changeTabNav);
			}

			prevTab = this;
			this.classList.add("active");
			this.removeEventListener("click", changeTabNav);
			let activeTab = cache.navDesk.querySelector("." + this.dataset.tab);

			cache.navDesk.style.display = "block";
			cache.navFilter.style.display = "block";
			activeTab.style.display = "flex";
			activeTab.style.opacity = 1;

			cache.navDesk.style.height = "auto";
			let height = cache.navDesk.offsetHeight;
			cache.navDesk.style.height = "0";

			if (this.classList.contains("active")) {
				this.removeEventListener("click", changeTabNav);
				this.addEventListener("click", closeNav);
			}

			document.querySelector("body").classList.add("overflow");
			document.querySelector("html").classList.add("overflow");

			anime({
				targets: cache.navDesk,
				height: height,
				duration: 400,
				easing: "easeOutQuad",
				complete: function() {
					cache.navCloseDesk.addEventListener("click", closeNav);
					cache.navFilter.addEventListener("click", closeNav);
				},
			});

			anime({
				targets: cache.navFilter,
				opacity: [0, 1],
				duration: 400,
				easing: "easeOutQuad",
			});
		};

		let closeNav = function() {
			let activeTitle = document.querySelector(
				"header .nav_top .top-title.active,  header .nav_top .search_btn.active"
			);
			let activeTab = cache.navDesk.querySelector(
				"." + activeTitle.dataset.tab
			);
			activeTitle.classList.remove("active");
			for (let i = 0; i < cache.navTriggerDesk.length; i++) {
				cache.navTriggerDesk[i].removeEventListener("click", changeTabNav);
			}

			prevTab = "";
			cache.navCloseDesk.removeEventListener("click", closeNav);
			cache.navFilter.removeEventListener("click", closeNav);

			cache.navTriggerDeskSearch.removeEventListener("click", changeTabNav);
			cache.navTriggerDeskSearch.removeEventListener(
				"click",
				changeTabNavSearchOut
			);

			document.querySelector("body").classList.remove("overflow");
			document.querySelector("html").classList.remove("overflow");

			anime({
				targets: cache.navDesk,
				height: 0,
				duration: 400,
				easing: "easeOutQuad",
				complete: function() {
					cache.navDesk.style.display = "none";
					activeTab.style.display = "none";
					for (let i = 0; i < cache.navTriggerDesk.length; i++) {
						cache.navTriggerDesk[i].addEventListener("click", openNav);
					}
					cache.navTriggerDeskSearch.addEventListener("click", openNav);
				},
			});

			anime({
				targets: cache.navFilter,
				opacity: [1, 0],
				duration: 400,
				easing: "easeOutQuad",
				complete: function() {
					cache.navFilter.style.display = "none";
				},
			});
		};

		let changeTabNav = function() {
			let activeTitle = document.querySelector(
				"header .nav_top .top-title.active"
			);
			if (!activeTitle) {
				if (cache.navTriggerDeskSearch.classList.contains("active")) {
					activeTitle = cache.navTriggerDeskSearch;
					activeTitle.addEventListener("click", changeTabNav);
					activeTitle.removeEventListener("click", changeTabNavSearchOut);
				}
			} else {
				activeTitle.removeEventListener("click", closeNav);
				activeTitle.addEventListener("click", changeTabNav);
			}
			activeTitle.classList.remove("active");
			let activeTab = cache.navDesk.querySelector(
				"." + activeTitle.dataset.tab
			);
			let targetTitle = this;
			targetTitle.classList.add("active");
			if (targetTitle.classList.contains("search_btn")) {
				targetTitle.removeEventListener("click", changeTabNav);
				targetTitle.addEventListener("click", changeTabNavSearchOut);
			} else {
				targetTitle.removeEventListener("click", changeTabNav);
				prevTab = targetTitle;
			}
			let targetTab = cache.navDesk.querySelector(
				"." + targetTitle.dataset.tab
			);

			this.addEventListener("click", () => {
				if (this.classList.contains("active")) {
					targetTitle.removeEventListener("click", changeTabNav);
					targetTitle.addEventListener("click", openNav);
					closeNav();
				}
			});

			anime({
				targets: activeTab,
				opacity: [1, 0],
				duration: 300,
				easing: "easeOutQuad",
				complete: function() {
					activeTab.style.display = "none";
				},
			});

			targetTab.style.display = "flex";
			anime({
				targets: targetTab,
				opacity: [0, 1],
				duration: 300,
				easing: "easeOutQuad",
			});
		};

		let changeTabNavSearchOut = function() {
			if (prevTab && !prevTab.classList.contains("search_btn")) {
				let activeTitle = cache.navTriggerDeskSearch;
				activeTitle.removeEventListener("click", changeTabNavSearchOut);
				activeTitle.addEventListener("click", changeTabNav);
				activeTitle.classList.remove("active");
				let activeTab = cache.navDesk.querySelector(
					"." + activeTitle.dataset.tab
				);

				let targetTitle = prevTab;
				targetTitle.classList.add("active");
				targetTitle.removeEventListener("click", changeTabNav);
				let targetTab = cache.navDesk.querySelector(
					"." + targetTitle.dataset.tab
				);

				anime({
					targets: activeTab,
					opacity: [1, 0],
					duration: 300,
					easing: "easeOutQuad",
					complete: function() {
						activeTab.style.display = "none";
					},
				});

				targetTab.style.display = "flex";
				anime({
					targets: targetTab,
					opacity: [0, 1],
					duration: 300,
					easing: "easeOutQuad",
				});
			} else {
				closeNav();
			}
		};

		// MOBILE
		let openTabMobile = function() {
			let targetTab = cache.navMob.querySelector("." + this.dataset.tab);
			targetTab.style.display = "flex";
			targetTab.classList.add("open");

			anime({
				targets: targetTab,
				opacity: [0, 1],
				translateX: ["50%", "0%"],
				duration: 300,
				easing: "easeOutQuad",
			});
		};

		let closeTabMobile = function() {
			let targetTab = this.closest(".menu_tab");
			targetTab.classList.remove("open");

			anime({
				targets: targetTab,
				opacity: [1, 0],
				translateX: ["0%", "50%"],
				duration: 300,
				easing: "easeOutQuad",
				complete: function() {
					targetTab.style.display = "none";
				},
			});
		};

		let openNavMobile = function() {
			if (cache.navTriggerDeskSearch.classList.contains("active")) {
				closeNav();
			}
			cache.navMob.style.display = "block";
			cache.navmobileBtn.classList.add("active");
			cache.navmobileBtn.removeEventListener("click", openNavMobile);
			cache.navmobileBtn.addEventListener("click", closeNavMobile);
			document.querySelector("body").classList.add("overflow");
			document.querySelector("html").classList.add("overflow");

			anime({
				targets: cache.navMob,
				opacity: [0, 1],
				translateX: ["50%", "0%"],
				duration: 300,
				easing: "easeOutQuad",
			});
		};
		let closeNavMobile = function() {
			cache.navmobileBtn.classList.remove("active");
			cache.navmobileBtn.addEventListener("click", openNavMobile);
			cache.navmobileBtn.removeEventListener("click", closeNavMobile);
			document.querySelector("body").classList.remove("overflow");
			document.querySelector("html").classList.remove("overflow");

			anime({
				targets: cache.navMob,
				opacity: [1, 0],
				translateX: ["0%", "50%"],
				duration: 300,
				easing: "easeOutQuad",
				complete: function() {
					cache.navMob.style.display = "none";
					let openTab = cache.navMob.querySelector(".menu_tab.open");
					if (openTab) {
						openTab.classList.remove("open");
						openTab.style.display = "none";
					}
				},
			});
		};

		let scrollToAnchor = function() {
			const currentUrl = document.URL;
			let urlParts = currentUrl.split("#");
			let id = "#" + urlParts[1];
			let element = document.querySelector(id);

			anime({
				targets: globalVar.scrollElement,
				scrollTop: utils.getOffsetTop(element) - 160,
				duration: 300,
				easing: "easeOutQuad",
				complete: function() {
					if (element.classList.contains("collapse_wrap")) {
						element.querySelector(".collapse_top").click();
					}
				},
			});
		};

		initFunc();
	}
}
export { Nav };

import utils from '../utils/utils'
import anime from '../libs/anime'
import Hammer from '../libs/hammer'
import globalVar from '../utils/globalVar'

class ModuleBw26_hoursTab {
  constructor() {
    let cache = {};
    let initFunc = () => {
      initCache();
      registerEvents();
    }

    let initCache = () => {
      cache.tabs = document.querySelectorAll('.module-bw-26-contact_hours .tab-name');
      cache.containers = document.querySelectorAll('.module-bw-26-contact_hours .tab_container');
    };

    let registerEvents = () => {
        for (let i = 0; i < cache.tabs.length; i++) {
            cache.tabs[i].addEventListener("click", changeTab)
        }
        for (let i = 0; i < cache.containers.length; i++) {
            anime({
                targets: cache.containers[i],
                height: cache.containers[i].querySelector('.tab').offsetHeight,
                duration: 100,
                easing: 'easeOutQuad',
            });
        }
    };


    // TAB
    let changeTab = function() {
        let parent = this.closest('.tab_wrapper');
        let currentTab = parent.querySelector('.tab-name.active');
        let targetTab = this;
        let targetIndex = parseInt(targetTab.dataset.tab);
        let currentIndex = parseInt(currentTab.dataset.tab);
        let tab = parent.querySelectorAll('.tab');

        let currentSlide = tab[currentIndex];
        let targetSlide = tab[targetIndex];
        
        currentTab.classList.remove('active');
        targetTab.classList.add('active');

        anime({
            targets: currentSlide,
            opacity: [1, 0],
            duration: 400,
            easing: 'easeOutQuad',
            complete: function() {
                currentSlide.style.display = "none";
            }
        });
        targetSlide.style.display = "block  ";
        anime({
            targets: targetSlide,
            opacity: [0, 1],
            duration: 400,
            easing: 'easeOutQuad',
        });
        anime({
            targets: parent.querySelector('.tab_container'),
            height: targetSlide.offsetHeight,
            duration: 400,
            easing: 'easeOutQuad',
        });
    }

    initFunc();
  };
}
export { ModuleBw26_hoursTab }
import utils from '../utils/utils'
import anime from '../libs/anime'
import globalVar from '../utils/globalVar'

class PopUpVid {
  constructor() {
    let cache = {};
    let initFunc = () => {
      initCache();
      registerEvents();
    }

    let initCache = () => {
      cache.popUpClicks = document.querySelectorAll('.popup_click');
      cache.popUpClose = document.querySelectorAll('.popup_video .bg, .popup_video .close');
    };

    let registerEvents = () => {
      for (let i = 0; i < cache.popUpClicks.length; i++) {
        cache.popUpClicks[i].addEventListener("click", openPopUp);
      }
      for (let i = 0; i < cache.popUpClose.length; i++) {
        cache.popUpClose[i].addEventListener("click", closePopUp);
      }
    };

    let openPopUp = function() {
      utils.disableScroll();
      const el = this;
      const popup_class = el.dataset.popup;
      const popup = document.querySelector('.'+popup_class);

      popup.style.display = "block";

      anime({
        targets: popup,
        opacity: [0,1],
        duration: 400,
        easing: 'easeOutQuad'
      });
      anime({
        targets: popup.querySelector('.inner'),
        translateX: ['-50%','-50%'],
        translateY: ['-50%','-50%'],
        scale: [0.9, 1],
        delay: 100,
        duration: 500,
        easing: 'easeOutExpo'
      });

      const iframe = popup.querySelector('iframe');
      if(iframe) {
        iframe.contentWindow.postMessage('{"event":"command","func":"playVideo","args":""}', '*');
      }

      const video = popup.querySelector('video');
      if(video) {
        video.play();
      }
    }

    let closePopUp = function() {
      const popup = this.closest('.popup_video');

      anime({
        targets: popup,
        opacity: [1,0],
        duration: 400,
        delay: 100,
        easing: 'easeOutQuad',
        complete: function() {
          popup.style.display = "none";
          utils.enableScroll();
          if(popup.querySelector('iframe')) {
            const iframe = popup.querySelector('iframe');
            const src = iframe.src;
            iframe.src = "";
            iframe.src = src;
          } else if(popup.querySelector('video')) {
            const vidPlayer = popup.querySelector('video');
            vidPlayer.pause();
          }
        }
      });
      anime({
        targets: popup.querySelector('.inner'),
        translateX: ['-50%','-50%'],
        translateY: ['-50%','-50%'],
        scale: [1,0.9],
        duration: 500,
        easing: 'easeOutExpo'
      });
    }

    initFunc();
  };
}
export { PopUpVid }

import anime from "../libs/anime";
import globalVar from "../utils/globalVar";

class Module31_MenuAncre {
  constructor() {
    let cache = {};
    let initFunc = () => {
      initCache();
      registerEvents();
    };

    let initCache = () => {
      cache.menu = document.querySelector(".module-31-menu-anchors");
      cache.container = document.querySelector(".page-container");
    };

    let registerEvents = () => {
      window.addEventListener("scroll", handleScroll);
    };

    let isVisible = (elem) => {
      const rect = elem.getBoundingClientRect();
      return rect.top - window.scrollY < rect.height;
    };

    let handleScroll = () => {
      if (globalVar.tablet.matches) {
        const containerTop =
          cache.container.getBoundingClientRect().top + window.scrollY;

        if (window.scrollY >= containerTop && isVisible(cache.menu)) {
          cache.menu.classList.add("fixed");
        } else {
          cache.menu.classList.remove("fixed");
        }
      }
    };

    initFunc();
  }
}
export { Module31_MenuAncre };

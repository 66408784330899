import utils from '../utils/utils'
import anime from '../libs/anime'
import Hammer from '../libs/hammer'
import globalVar from '../utils/globalVar'

class Module16_SliderTab {
  constructor() {
    let cache = {};
    let initFunc = () => {
      initCache();
      registerEvents();
      
    }

    let initCache = () => {
      cache.sliderTabs = document.querySelectorAll('.module-16-slider_tab');
    };

    let registerEvents = () => {
        for (let i = 0; i < cache.sliderTabs.length; i++) {
            cache.sliderTabs[i].querySelector('.tab_container').style.height = cache.sliderTabs[i].querySelector('.tab-slide .tab-slide_inner').offsetHeight + "px";
            if(cache.sliderTabs[i].querySelector('.slider_nav')) {
                let slider_nav = cache.sliderTabs[i].querySelectorAll('.slider_nav');
                let tab_cards_cta = cache.sliderTabs[i].querySelectorAll('.tab-cards_cta');
                let tab_card = cache.sliderTabs[i].querySelectorAll('.tab-card');

                for (let ii = 0; ii < slider_nav.length; ii++) {
                    slider_nav[ii].querySelector('.tab-card_arrow-left').addEventListener("click", function() {
                        slideLeft(this.closest('.tab-slide'));
                    });
                    slider_nav[ii].querySelector('.tab-card_arrow-right').addEventListener("click", function() {
                        slideRight(this.closest('.tab-slide'));
                    });
                }
                for (let ii = 0; ii < tab_cards_cta.length; ii++) {
                    tab_cards_cta[ii].addEventListener("click", function() {
                        let currentIndex = parseInt(this.closest('.tab-slide').dataset.index);
                        let targetIndex = parseInt(this.dataset.tab);

                        if(currentIndex < targetIndex) {
                            slideToLeft(this.closest('.tab-slide'), targetIndex);
                        }
                        else {
                            slideToRight(this.closest('.tab-slide'), targetIndex);
                        }
                    });
                }
                for (let ii = 0; ii < tab_card.length; ii++) {
                    let hammertime = new Hammer(tab_card[ii]);
                    hammertime.on('swipe', function(ev) {
                        if(ev.direction == 2) {
                            slideLeft(tab_card[ii].closest('.tab-slide'));
                        }
                        if(ev.direction == 4) {
                            slideRight(tab_card[ii].closest('.tab-slide'));
                        }
                    });
                }
            }
            
            if(cache.sliderTabs[i].querySelector('.tab-name')) {
                let tabName = cache.sliderTabs[i].querySelectorAll('.tab-name');
                for (let ii = 0; ii < tabName.length; ii++) {
                    tabName[ii].addEventListener("click", changeTab);
                }
            }
        }
    };

    let slideLeft = function(slider) {
        let tab = slider.querySelectorAll('.tab-cards_title-wrap .tab-cards_cta');
        let slides = slider.querySelectorAll('.tab-card');
        let index = parseInt(slider.dataset.index);
        tab[index].classList.remove('active');
        
        let currentSlide = slides[index];

        if(index == 0) {
            index = slides.length-1;
        } else {
            index--;
        }

        let targetSlide = slides[index];
        slider.dataset.index = index;
        tab[index].classList.add('active');

        anime({
            targets: currentSlide,
            opacity: [1, 0],
            duration: 400,
            easing: 'easeOutQuad',
            complete: function() {
                currentSlide.style.display = "none";
            }
        });
        anime({
            targets: currentSlide.querySelector('.inner'),
            opacity: [1, 0],
            translateX: ["-50%", '-50%'],
            translateY: ["-50%", '-50%'],
            rotate: [0, 15],
            scale: [1, 0.8],
            duration: 400,
            easing: 'easeOutQuad',
            complete: function() {
                currentSlide.style.display = "none";
            }
        });
            
        targetSlide.style.display = "block";
        anime({
            targets: targetSlide,
            opacity: [0, 1],
            duration: 400,
            easing: 'easeOutQuad',
        });
        anime({
            targets: targetSlide.querySelector('.inner'),
            opacity: [0, 1],
            translateX: ["-50%", '-50%'],
            translateY: ["-50%", '-50%'],
            rotate: [-15, 0],
            scale: [0.8, 1],
            duration: 400,
            easing: 'easeOutQuad',
            complete: function() {
                currentSlide.style.display = "none";
            }
        });

        anime({
            targets: slider,
            height: targetSlide.offsetHeight,
            duration: 400,
            easing: 'easeOutQuad',
        });

    }

    let slideRight = function(slider) {
        let tab = slider.querySelectorAll('.tab-cards_title-wrap .tab-cards_cta');
        let slides = slider.querySelectorAll('.tab-card');
        let index = parseInt(slider.dataset.index);
        tab[index].classList.remove('active');
        
        let currentSlide = slides[index];

        if(index == slides.length-1) {
            index = 0;
        } else {
            index++;
        }

        let targetSlide = slides[index];
        slider.dataset.index = index;
        tab[index].classList.add('active');

        anime({
            targets: currentSlide,
            opacity: [1, 0],
            duration: 400,
            easing: 'easeOutQuad',
            complete: function() {
                currentSlide.style.display = "none";
            }
        });
        anime({
            targets: currentSlide.querySelector('.inner'),
            opacity: [1, 0],
            translateX: ["-50%", '-50%'],
            translateY: ["-50%", '-50%'],
            rotate: [0, -15],
            scale: [1, 0.8],
            duration: 400,
            easing: 'easeOutQuad',
            complete: function() {
                currentSlide.style.display = "none";
            }
        });
            
        targetSlide.style.display = "block";
        anime({
            targets: targetSlide,
            opacity: [0, 1],
            duration: 400,
            easing: 'easeOutQuad',
        });
        anime({
            targets: targetSlide.querySelector('.inner'),
            opacity: [0, 1],
            translateX: ["-50%", '-50%'],
            translateY: ["-50%", '-50%'],
            rotate: [15, 0],
            scale: [0.8, 1],
            duration: 400,
            easing: 'easeOutQuad',
            complete: function() {
                currentSlide.style.display = "none";
            }
        });

        anime({
            targets: slider,
            height: targetSlide.offsetHeight,
            duration: 400,
            easing: 'easeOutQuad',
        });

    }



    let slideToLeft = function(slider, targetIndex) {
        let tab = slider.querySelectorAll('.tab-cards_title-wrap .tab-cards_cta');
        let slides = slider.querySelectorAll('.tab-card');
        let index = parseInt(slider.dataset.index);
        tab[index].classList.remove('active');
        
        let currentSlide = slides[index];


        let targetSlide = slides[targetIndex];
        slider.dataset.index = targetIndex;
        tab[targetIndex].classList.add('active');

        anime({
            targets: currentSlide,
            opacity: [1, 0],
            duration: 400,
            easing: 'easeOutQuad',
            complete: function() {
                currentSlide.style.display = "none";
            }
        });
        anime({
            targets: currentSlide.querySelector('.inner'),
            opacity: [1, 0],
            translateX: ["-50%", '-50%'],
            translateY: ["-50%", '-50%'],
            rotate: [0, 15],
            scale: [1, 0.8],
            duration: 400,
            easing: 'easeOutQuad',
            complete: function() {
                currentSlide.style.display = "none";
            }
        });
            
        targetSlide.style.display = "block";
        anime({
            targets: targetSlide,
            opacity: [0, 1],
            duration: 400,
            easing: 'easeOutQuad',
        });
        anime({
            targets: targetSlide.querySelector('.inner'),
            opacity: [0, 1],
            translateX: ["-50%", '-50%'],
            translateY: ["-50%", '-50%'],
            rotate: [-15, 0],
            scale: [0.8, 1],
            duration: 400,
            easing: 'easeOutQuad',
            complete: function() {
                currentSlide.style.display = "none";
            }
        });

        anime({
            targets: slider,
            height: targetSlide.offsetHeight,
            duration: 400,
            easing: 'easeOutQuad',
        });

    }

    let slideToRight = function(slider,targetIndex) {
        let tab = slider.querySelectorAll('.tab-cards_title-wrap .tab-cards_cta');
        let slides = slider.querySelectorAll('.tab-card');
        let index = parseInt(slider.dataset.index);
        tab[index].classList.remove('active');
        
        let currentSlide = slides[index];


        let targetSlide = slides[targetIndex];
        slider.dataset.index = targetIndex;
        tab[targetIndex].classList.add('active');

        anime({
            targets: currentSlide,
            opacity: [1, 0],
            duration: 400,
            easing: 'easeOutQuad',
            complete: function() {
                currentSlide.style.display = "none";
            }
        });
        anime({
            targets: currentSlide.querySelector('.inner'),
            opacity: [1, 0],
            translateX: ["-50%", '-50%'],
            translateY: ["-50%", '-50%'],
            rotate: [0, -15],
            scale: [1, 0.8],
            duration: 400,
            easing: 'easeOutQuad',
            complete: function() {
                currentSlide.style.display = "none";
            }
        });
            
        targetSlide.style.display = "block";
        anime({
            targets: targetSlide,
            opacity: [0, 1],
            duration: 400,
            easing: 'easeOutQuad',
        });
        anime({
            targets: targetSlide.querySelector('.inner'),
            opacity: [0, 1],
            translateX: ["-50%", '-50%'],
            translateY: ["-50%", '-50%'],
            rotate: [15, 0],
            scale: [0.8, 1],
            duration: 400,
            easing: 'easeOutQuad',
            complete: function() {
                currentSlide.style.display = "none";
            }
        });

        anime({
            targets: slider,
            height: targetSlide.offsetHeight,
            duration: 400,
            easing: 'easeOutQuad',
        });

    }



    // TAB
    let changeTab = function() {
        let parent = this.closest('.tab_wrapper');
        let targetIndex = parseInt(this.dataset.tab);
        let currentIndex = parseInt(parent.querySelector('.tab-name.active').dataset.tab);
        let tab = parent.querySelectorAll('.tab-name');
        let tab_slide = parent.querySelectorAll('.tab-slide');

        let currentTab = tab[currentIndex];
        let targetTab = tab[targetIndex];

        let currentSlide = tab_slide[currentIndex];
        let targetSlide = tab_slide[targetIndex];
        
        currentTab.classList.remove('active');
        targetTab.classList.add('active');

        anime({
            targets: currentSlide,
            opacity: [1, 0],
            duration: 400,
            easing: 'easeOutQuad',
            complete: function() {
                currentSlide.style.display = "none";
            }
        });
        targetSlide.style.display = "block";
        anime({
            targets: targetSlide,
            opacity: [0, 1],
            duration: 400,
            easing: 'easeOutQuad',
        });
        anime({
            targets: parent.querySelector('.tab_container'),
            height: targetSlide.querySelector('.tab-slide_inner').offsetHeight,
            duration: 400,
            easing: 'easeOutQuad',
        });
    }



    initFunc();
  };
}
export { Module16_SliderTab }
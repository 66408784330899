import utils from '../utils/utils'
import anime from '../libs/anime'
import Hammer from '../libs/hammer'
import globalVar from '../utils/globalVar'

class Module15_TimelineTab {
  constructor() {
    let cache = {};
    let initFunc = () => {
      initCache();
      registerEvents();
      
    }

    let initCache = () => {
      cache.timelineTabs = document.querySelectorAll('.module-15-timeline.layout-tab');
    };

    let registerEvents = () => {
        if(document.querySelector('.module-15-timeline.layout-tab')) {
            for (let i = 0; i < cache.timelineTabs.length; i++) {
                cache.timelineTabs[i].querySelector('.timeline-tab_slider').style.height = cache.timelineTabs[i].querySelector('.timeline-tab_slide').offsetHeight + "px";
                cache.timelineTabs[i].querySelector('.arrow_left').addEventListener("click", function() {
                    slideLeft(cache.timelineTabs[i]);
                });
                cache.timelineTabs[i].querySelector('.arrow_right').addEventListener("click", function() {
                    slideRight(cache.timelineTabs[i]);
                });
                let tabName = cache.timelineTabs[i].querySelectorAll('.tab-name')
                
                for (let ii = 0; ii < tabName.length; ii++) {
                    tabName[ii].addEventListener("click", function() {
                        let currentIndex = parseInt(cache.timelineTabs[i].querySelector('.timeline-tab_slider').dataset.index);
                        let targetIndex = parseInt(this.dataset.tab);

                        if(currentIndex < targetIndex) {
                            slideToRight(cache.timelineTabs[i], targetIndex);
                        }
                        else {
                            slideToLeft(cache.timelineTabs[i], targetIndex);
                        }
                    });
                }
        
                    let hammertime = new Hammer(cache.timelineTabs[i].querySelector('.timeline-tab_slider'));
                    hammertime.on('swipe', function(ev) {
                    if(ev.direction == 2) {
                        slideRight(cache.timelineTabs[i]);
                    }
                    if(ev.direction == 4) {
                        slideLeft(cache.timelineTabs[i]);
                    }
                  });
            }
        }
    };

    let slideLeft = function(parent) {
        let tab = parent.querySelectorAll('.timeline-tab_name-container .tab-name');
        let slider = parent.querySelector('.timeline-tab_slider');
        let slides = slider.querySelectorAll('.timeline-tab_slide');
        let index = parseInt(slider.dataset.index);
        tab[index].classList.remove('active');
        
        let currentSlide = slides[index];

        if(index == 0) {
            index = slides.length-1;
        } else {
            index--;
        }

        let targetSlide = slides[index];
        slider.dataset.index = index;
        tab[index].classList.add('active');

        if(globalVar.tablet.matches) {
            anime({
                targets: currentSlide,
                translateX: ["0%", "150%"],
                duration: 400,
                easing: 'easeOutQuad',
                complete: function() {
                    currentSlide.style.display = "none";
                }
            });
            
            targetSlide.style.display = "flex";
            anime({
                targets: targetSlide,
                translateX: ["-150%", "0%"],
                duration: 400,
                easing: 'easeOutQuad',
            });
        } else {
            anime({
                targets: currentSlide,
                translateX: ["0%", "150%"],
                duration: 400,
                easing: 'easeOutQuad',
                complete: function() {
                    currentSlide.style.display = "none";
                }
            });
            
            targetSlide.style.display = "flex";
            anime({
                targets: targetSlide,
                translateX: ["-150%", "0%"],
                duration: 400,
                easing: 'easeOutQuad',
            });
        }

        anime({
            targets: slider,
            height: targetSlide.offsetHeight,
            duration: 400,
            easing: 'easeOutQuad',
        });

        anime({
            targets: parent.querySelector(".timeline-tab_name-container"),
            scrollLeft: tab[index].offsetLeft,
            duration: 400,
            easing: 'easeOutQuad',
        });
    }

    let slideRight = function(parent) {
        let tab = parent.querySelectorAll('.timeline-tab_name-container .tab-name');
        let slider = parent.querySelector('.timeline-tab_slider');
        let slides = slider.querySelectorAll('.timeline-tab_slide');
        let index = parseInt(slider.dataset.index);
        tab[index].classList.remove('active');
        
        let currentSlide = slides[index];

        if(index == slides.length-1) {
            index = 0;
        } else {
            index++;
        }

        let targetSlide = slides[index];
        slider.dataset.index = index;
        tab[index].classList.add('active');

        if(globalVar.tablet.matches) {
            anime({
                targets: currentSlide,
                translateX: ["0%", "-150%"],
                duration: 400,
                easing: 'easeOutQuad',
                complete: function() {
                    currentSlide.style.display = "none";
                }
            });
            targetSlide.style.display = "flex";
            anime({
                targets: targetSlide,
                translateX: ["150%", "0%"],
                duration: 400,
                easing: 'easeOutQuad',
            });
        } else {
            anime({
                targets: currentSlide,
                translateX: ["0%", "-150%"],
                duration: 400,
                easing: 'easeOutQuad',
                complete: function() {
                    currentSlide.style.display = "none";
                }
            });
            targetSlide.style.display = "flex";
            anime({
                targets: targetSlide,
                translateX: ["150%", "0%"],
                duration: 400,
                easing: 'easeOutQuad',
            });
        }

        anime({
            targets: slider,
            height: targetSlide.offsetHeight,
            duration: 400,
            delay: 400,
            easing: 'easeOutQuad',
        });

        anime({
            targets: parent.querySelector(".timeline-tab_name-container"),
            scrollLeft: tab[index].offsetLeft,
            duration: 400,
            easing: 'easeOutQuad',
        });
    }



    let slideToLeft = function(parent, targetIndex) {
        let tab = parent.querySelectorAll('.timeline-tab_name-container .tab-name');
        let slider = parent.querySelector('.timeline-tab_slider');
        let slides = slider.querySelectorAll('.timeline-tab_slide');
        let index = parseInt(slider.dataset.index);
        tab[index].classList.remove('active');
        
        let currentSlide = slides[index];

        let targetSlide = slides[targetIndex];
        slider.dataset.index = targetIndex;
        tab[targetIndex].classList.add('active');

        if(globalVar.tablet.matches) {
            anime({
                targets: currentSlide,
                translateX: ["0%", "150%"],
                duration: 400,
                easing: 'easeOutQuad',
                complete: function() {
                    currentSlide.style.display = "none";
                }
            });
            
            targetSlide.style.display = "flex";
            anime({
                targets: targetSlide,
                translateX: ["-150%", "0%"],
                duration: 400,
                easing: 'easeOutQuad',
            });
        } else {
            anime({
                targets: currentSlide,
                translateX: ["0%", "150%"],
                duration: 400,
                easing: 'easeOutQuad',
                complete: function() {
                    currentSlide.style.display = "none";
                }
            });
            
            targetSlide.style.display = "flex";
            anime({
                targets: targetSlide,
                translateX: ["-150%", "0%"],
                duration: 400,
                easing: 'easeOutQuad',
            });
        }
        anime({
            targets: slider,
            height: targetSlide.offsetHeight,
            duration: 400,
            easing: 'easeOutQuad',
        });
        anime({
            targets: parent.querySelector(".timeline-tab_name-container"),
            scrollLeft: tab[targetIndex].offsetLeft,
            duration: 400,
            easing: 'easeOutQuad',
        });
    }

    let slideToRight = function(parent, targetIndex) {
        let tab = parent.querySelectorAll('.timeline-tab_name-container .tab-name');
        let slider = parent.querySelector('.timeline-tab_slider');
        let slides = slider.querySelectorAll('.timeline-tab_slide');
        let index = parseInt(slider.dataset.index);
        tab[index].classList.remove('active');
        
        let currentSlide = slides[index];

       
        let targetSlide = slides[targetIndex];
        slider.dataset.index = targetIndex;
        tab[targetIndex].classList.add('active');

        if(globalVar.tablet.matches) {
            anime({
                targets: currentSlide,
                translateX: ["0%", "-150%"],
                duration: 400,
                easing: 'easeOutQuad',
                complete: function() {
                    currentSlide.style.display = "none";
                }
            });
            targetSlide.style.display = "flex";
            anime({
                targets: targetSlide,
                translateX: ["150%", "0%"],
                duration: 400,
                easing: 'easeOutQuad',
            });
        } else {
            anime({
                targets: currentSlide,
                translateX: ["0%", "-150%"],
                duration: 400,
                easing: 'easeOutQuad',
                complete: function() {
                    currentSlide.style.display = "none";
                }
            });
            targetSlide.style.display = "flex";
            anime({
                targets: targetSlide,
                translateX: ["150%", "0%"],
                duration: 400,
                easing: 'easeOutQuad',
            });
        }
        anime({
            targets: slider,
            height: targetSlide.offsetHeight,
            duration: 400,
            easing: 'easeOutQuad',
        });
        anime({
            targets: parent.querySelector(".timeline-tab_name-container"),
            scrollLeft: tab[targetIndex].offsetLeft,
            duration: 400,
            easing: 'easeOutQuad',
        });
    }

    initFunc();
  };
}
export { Module15_TimelineTab }
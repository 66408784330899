import utils from '../utils/utils'
import anime from '../libs/anime'
import globalVar from '../utils/globalVar'

class CopyClipboard {
  constructor() {
    let cache = {};
    let initFunc = () => {
      initCache();
      registerEvents();
    }

    let initCache = () => {
      cache.copy = document.querySelectorAll('.copy_link');
    };

    let registerEvents = () => {
      for (let i = 0; i < cache.copy.length; i++) {
        cache.copy[i].addEventListener("click", copyLink);
      }
    };

    let copyLink = function() {
     let copyText = window.location.href;
     const textArea = document.createElement("textarea");
     textArea.value = copyText;
     textArea.classList.add('hiddenText')
     document.body.appendChild(textArea);
     textArea.focus({preventScroll: true})
     textArea.focus();
     textArea.select();
     try {
       document.execCommand('copy');
     } catch (err) {
       console.error('Unable to copy to clipboard', err);
     }
     document.body.removeChild(textArea);
    };

    initFunc();
  };
}
export { CopyClipboard }
import utils from '../utils/utils'
import anime from '../libs/anime'
import Hammer from '../libs/hammer'
import globalVar from '../utils/globalVar'

class Module30_Board {
  constructor() {
    let cache = {};
    let initFunc = () => {
      initCache();
      registerEvents();
      
    }

    let initCache = () => {
      cache.sliderTabs = document.querySelectorAll('.module-30-board');
    };

    let registerEvents = () => {
        for (let i = 0; i < cache.sliderTabs.length; i++) {
            
            if(cache.sliderTabs[i].querySelector('.tab-name')) {
                let tabName = cache.sliderTabs[i].querySelectorAll('.tab-name');
                for (let ii = 0; ii < tabName.length; ii++) {
                    tabName[ii].addEventListener("click", changeTab);
                }
            }
        }
    };

    // TAB
    let changeTab = function() {
        let parent = this.closest('.tab_wrapper');
        let targetIndex = parseInt(this.dataset.tab);

        let currentIndex = parseInt(parent.querySelector('.tab-name.active').dataset.tab);
        let tab = parent.querySelectorAll('.tab-name');
        let tab_slide = parent.querySelectorAll('.tab-list');
        
        console.log(currentIndex);
        let currentTab = tab[currentIndex];
        let targetTab = tab[targetIndex];

        let currentSlide = tab_slide[currentIndex];
        let targetSlide = tab_slide[targetIndex];
        
        currentTab.classList.remove('active');
        targetTab.classList.add('active');

        anime({
            targets: currentSlide,
            opacity: [1, 0],
            duration: 400,
            easing: 'easeOutQuad',
            complete: function() {
                currentSlide.style.display = "none";
            }
        });
        targetSlide.style.display = "block";
        anime({
            targets: targetSlide,
            opacity: [0, 1],
            duration: 400,
            easing: 'easeOutQuad',
        });
        anime({
            targets: parent.querySelector('.tab_container'),
            height: targetSlide.querySelector('.tab-list_inner').offsetHeight,
            duration: 400,
            easing: 'easeOutQuad',
        });
    }



    initFunc();
  };
}
export { Module30_Board }